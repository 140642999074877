import Vue from 'vue'
import App from './App.vue'
import router from './router';
import './assets/styles/base.scss';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/github-markdown-css/github-markdown.css'; //哪里用markdown 就在哪里引用 可以放在min.js
import DlTable from "./private-components/table/DlTable";
import DlTableColumn from "./private-components/table/DlTableColumn";
// Vue.use(VueMarkdown);
Vue.component("DlTable", DlTable);
Vue.component("DlTableColumn", DlTableColumn);
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$equipment = function () {
  let flag = navigator.userAgent.match(/(phone|MiuiBrowser|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return flag;
}
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
