import NProgress from "nprogress";
import { stringifyQuery, parseQuery } from "./query.js";
import "nprogress/nprogress.css";
import VueRouter from "vue-router";
import Vue from "vue";
import g from '@/utils/global';
Vue.use(VueRouter);
const routes = [{
  name: "index",
  path: "/",
  component: resolve => require(["../views/Index/Index.vue"], resolve),
  meta: { 
    title: "兆峰AI - 首页",
    content: {
      keywords: "兆峰,人工智能,AI,ChatGPT,GPT,生成式模型,聊天机器人,兆峰智能,兆峰智能科技,温州兆峰,大语言模型,AI绘画"
    }
  }
}, {
  name: "chat",
  path: "/chat",
  component: resolve => require(["../views/Chat/Chat.vue"], resolve),
  meta: { 
    title: "兆峰AI",
    content: {
      keywords: "兆峰,人工智能,AI,ChatGPT,GPT,生成式模型,聊天机器人,兆峰智能,兆峰智能科技,温州兆峰,大语言模型,AI绘画"
    }
  }
}, {
  name: "404",
  path: "/404",
  component: resolve => require(["../views/ErrorPages/404.vue"], resolve),
  meta: { title: "兆峰AI" }
}, {
  name: "servererr",
  path: "/servererr",
  component: resolve => require(['../views/ErrorPages/serverErr.vue'], resolve),
  meta: { title: "servererr" }
}, {
  name: "plugin",
  path: "/plugin",
  component: resolve => require(["../views/Index/Plugin.vue"], resolve),
  meta: { 
    title: "兆峰AI - Chrome插件",
    content: {
      keywords: "ChatGPT插件、ChatGPT浏览器、人工智能插件、浏览器插件、Chrom插件"
    }
  }
}, {
  path: "*",
  name: "404",
  component: resolve => require(["../views/ErrorPages/404.vue"], resolve),
  meta: { title: "兆峰AI" }
}];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  mode: "history",
  stringifyQuery: stringifyQuery, // 序列化query参数
  parseQuery: parseQuery, // 反序列化query参数
  routes
})

NProgress.configure({ showSpinner: false });

router.beforeEach(async (to, _from, next) => {
  const params = g.GetHrefParams();
  if (params.aicode) {
    sessionStorage.setItem("aicode", params.aicode);
  }
  if (params.OpenID) {
    sessionStorage.setItem("OpenID", params.OpenID);
    if (params.PointPay) {
      sessionStorage.setItem("PointPay", params.PointPay);
    }
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if(to.meta.content){
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  NProgress.start();
  next();
});

router.afterEach(_e => {
  NProgress.done();
});

export default router;
