const g = {
  isShowMemory: true,
  merge (d, b, cover) {
    if (b) {
      for (let k in b) {
        if (typeof b[k] == 'object' && (!d[k] || typeof d[k] == 'object')) {
          if (Array.isArray(b[k])) {
            d[k] = d[k] || [];
          } else if (b[k] === null) {
            d[k] = null;
          } else if (b[k] === undefined) {
            d[k] = undefined
          } else {
            d[k] = d[k] || {};
          }
          this.merge(d[k], b[k], cover);
        } else {
          !(!cover && d.hasOwnProperty(k)) && (d[k] = b[k]);
        }
      }
    }
    return d;
  },
  GetHrefParams () {
    if (!/\?/g.test(location.href)) return {};
    let hrefParams;
    hrefParams = location.href.replace(/.*\?/, '').split('&');
    let ObjectParams = {};
    for (let i = 0; i < hrefParams.length; i++) {
      let e = hrefParams[i].split('=');
      ObjectParams[e[0]] = e[1];
    }
    return ObjectParams;
  }
}


export function GetUuid () {
  let s = [];
  let hexDigits = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let floorDigits = '';
  for (let i = 0; i < 36; i++) {
    floorDigits = Math.floor(Math.random() * 0x24);
    s[i] = hexDigits.substring(floorDigits, floorDigits + 1);
  }
  s[14] = '4';
  s[19] = hexDigits.substring((s[19] & 0x3) | 0x8, ((s[19] & 0x3) | 0x8) + 1);
  s[8] = s[13] = s[18] = s[23] = '-';
  return s.join('');
}

export default g;