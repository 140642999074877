export default {
  name: "DlTableColumn",
  props: {
    hiddenStr: String,
    prop: String,
    row: Object,
    rowIndex: Number,
    reserveSelection: Boolean,
    rowKey: String,
    disabled: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: ""
    },
    fixed: String,
    cellIndex: Number,
    sortable: String,
    align: String,
    children: {
      type: Array,
      default() {
        return [];
      }
    },
    showOverflowTooltip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      Checked: true,
      showTip: true,
      fun: null
    };
  },
  created() {
    if (this.rowKey && !this.row[this.rowKey]) {
      console.error(
        `The current line ${this.rowIndex}: the value of the row key binding attribute is null or undefined. Please check.`
      );
    }
  },
  computed: {
    newProp() {
      return JSON.parse(JSON.stringify(this.row))[this.prop];
    }
  },
  watch: {
    "$parent.CheckKeys"() {
      this.$forceUpdate();
    },
    newProp: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$nextTick(() => {
          this.debounce(this.HandleSum);
        });
      }
    }
  },
  render() {
    switch (this.type) {
      case "selection":
        this.GetShowCheckStatus();
        return this.hiddenStr && this.row[this.hiddenStr] ? (
          <td
            class={this.GetFixedObj("class")}
            key={`column_${this.rowIndex}_${this.cellIndex}`}
          ></td>
        ) : (
          <td
            key={`column_${this.rowIndex}_${this.cellIndex}`}
            class={this.GetFixedObj("class")}
            align={this.align}
            style={this.GetFixedObj("style")}
          >
            <el-checkbox
              v-model={this.Checked}
              onChange={this.onChange_Row}
              disabled={!this.disabled}
            />
          </td>
        );
      case "index":
        return (
          <td
            key={`column_${this.rowIndex}_${this.cellIndex}`}
            class={this.GetFixedObj("class")}
            align={this.align}
            style={this.GetFixedObj("style")}
          >
            {this.rowIndex + 1}
          </td>
        );
      default:
        return (
          <td
            ref="tdRef"
            key={`column_${this.rowIndex}_${this.cellIndex}`}
            class={this.GetFixedObj("class")}
            align={this.align}
            style={this.GetFixedObj("style")}
            onMouseover={this.onMouseover}
            onMouseleave={this.onMouseleave}
          >
            {!this.children.length ? (
              this.showOverflowTooltip ? (
                !this.showTip ? (
                  <el-tooltip
                    effect="dark"
                    content={this.row[this.prop]}
                    placement="top"
                    disabled={this.showTip}
                  >
                    <span class="ellipsis">{this.row[this.prop] ||
                      String(this.row[this.prop]) === "0"
                        ? this.row[this.prop]
                        : "-"}</span>
                  </el-tooltip>
                ) : (
                  <span class="ellipsis">{this.row[this.prop] || String(this.row[this.prop]) === "0"
                      ? this.row[this.prop]
                      : "-"}</span>
                )
              ) : this.row[this.prop] || String(this.row[this.prop]) === "0" ? (
                this.row[this.prop]
              ) : (
                "-"
              )
            ) : (
              this.children.filter(v => v.tag !== "template")
            )}
          </td>
        );
    }
  },
  methods: {
    debounce: function(fn) {
      if (this.fun !== null) {
        clearTimeout(this.fun);
      }
      this.fun = setTimeout(fn, 0);
    },
    HandleSum() {
      this.$parent.SummaryTable(this.$parent.data);
    },
    onMouseover() {
      if (this.children.length || !this.showOverflowTooltip) return;
      this.showTip =
        this.$refs.tdRef.firstElementChild.scrollWidth <
        this.$refs.tdRef.offsetWidth - 12;
    },
    onMouseleave() {
      this.showTip = true;
    },
    GetShowCheckStatus() {
      // 复选框回显
      if (this.rowKey && this.reserveSelection) {
        let Checkkey = this.$parent.CheckRowKey[this.row[this.rowKey]];
        if (this.disabled) {
          this.Checked = !!Checkkey;
        } else {
          delete this.$parent.CheckRowKey[Checkkey];
          this.Checked = false;
        }
      } else {
        let CheckIdx = this.$parent.CheckKeys.indexOf(this.rowIndex);
        if (this.disabled) {
          this.Checked = CheckIdx > -1;
        } else {
          if (CheckIdx > -1) {
            this.$parent.CheckKeys.splice(CheckIdx, 1);
          }
          this.Checked = false;
        }
      }
    },
    GetFixedObj(type) {
      if (this.$parent.DTableContainerWidth) {
        return "";
      }
      switch (this.fixed) {
        case "":
          if (type === "class") {
            let LeftFixeds = this.$parent.SlotObj.filter(v => {
              if (this.$parent.JudgeHaveHeaders.includes(this.$parent.initEvalLabel(v)) && v.componentOptions.propsData.fixed === "") {
                return v;
              }
            });
            return `DTableFixedLeft  ${
              LeftFixeds.length - 1 == this.cellIndex
                ? "DTableFixedLeftShowdow"
                : ""
            }`;
          }
          return this.cellIndex > 0 ? `left:${this.GetPreWidth()}px` : "";
        case "left":
          if (type === "class") {
            let LeftFixeds = this.$parent.SlotObj.filter(v => {
              if (this.$parent.JudgeHaveHeaders.includes(this.$parent.initEvalLabel(v)) && v.componentOptions.propsData.fixed === "left") {
                return v;
              }
            });
            return `DTableFixedLeft  ${
              LeftFixeds.length - 1 == this.cellIndex
                ? "DTableFixedLeftShowdow"
                : ""
            }`;
          }
          return this.cellIndex > 0 ? `left:${this.GetPreWidth()}px` : "";
        case "right":
          if (type === "class") {
            let RightFixeds = this.$parent.SlotObj.filter((v, i) => {
              if (this.$parent.JudgeHaveHeaders.includes(this.$parent.initEvalLabel(v)) && v.componentOptions.propsData.fixed === "right") {
                v["FixedsIndex"] = i;
                return v;
              }
            });
            return `DTableFixedRight ${
              RightFixeds[0].FixedsIndex == this.cellIndex
                ? "DTableFixedRightShowdow"
                : ""
            }`;
          }
          return this.cellIndex == this.$parent.SlotObj.length - 1
            ? ""
            : `right:${this.GetNextWidth(this.cellIndex)}px;z-index:3;`;
        default:
          return "";
      }
    },
    GetPreWidth() {
      let total = 0;
      this.$parent.SlotObj.slice(0, this.cellIndex).map(v => {
        if (this.$parent.JudgeHaveHeaders.includes(this.$parent.initEvalLabel(v))) {
          total += Number(v.data.attrs.width);
        }
      });
      return total;
    },
    GetNextWidth() {
      let total = 0;
      this.$parent.SlotObj.slice(this.cellIndex + 1).map(v => {
        if (this.$parent.JudgeHaveHeaders.includes(this.$parent.initEvalLabel(v))) {
          total += Number(v.data.attrs.width);
        }
      });
      return total;
    },
    onChange_Row(e) {
      if (e) {
        this.$parent.CheckKeys.push(this.rowIndex);
        if (this.rowKey && this.reserveSelection) {
          this.$parent.CheckRowKey[this.row[this.rowKey]] = this.row;
        }
      } else {
        this.$parent.CheckKeys.splice(
          this.$parent.CheckKeys.indexOf(this.rowIndex),
          1
        );
        if (this.rowKey && this.reserveSelection) {
          delete this.$parent.CheckRowKey[this.row[this.rowKey]];
        }
      }
      this.$parent.$emit(
        "select",
        this.rowKey && this.reserveSelection
          ? Object.values(this.$parent.CheckRowKey)
          : this.$parent.CheckKeys.map(v => this.$parent.data[v]),
        this.$parent.data[this.rowIndex]
      );
    }
  }
};
