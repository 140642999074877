import "../../assets/styles/DlCompnent.scss";
import g, { GetUuid } from "../../utils/global";
import operation from "./operation.vue";
let ThisDownID = null;
let DownDom = null;
let maxDom = null;
let CoverDom = {};
let x = 0;
let y = 0;
let l = 0;
let isMouseDown = null;
let DownTime = null;
let DownTimer = null;
let startX = null;
export default {
  name: "DlTable",
  props: {
    data: {
      // 数据
      type: Array,
      default() {
        return [];
      }
    },
    height: {
      // 行高
      type: Number
    },
    isShowAllCheck: {
      // 是否显示全选按钮
      type: Boolean,
      default: true
    },
    stripe: {
      // 是否开启斑马纹
      type: Boolean,
      default: false
    },
    highlightCurrentRow: {
      // 是否高亮当前行
      type: Boolean,
      default: true
    },
    summaryHeaderWidth: {
      type: Function,
      default: () => {
        return null;
      }
    },
    headerCellClassName: {
      type: Function,
      default: () => null
    },
    isOperation: {
      // 是否显示配置属性
      type: Boolean,
      default: true
    },
    showSummary: {
      // 是否开启合计
      type: Boolean,
      default: false
    },
    IsChooseSummary:{// 是否指定合计行
      type: Boolean,
      default: false
    },
    summaryMethod: Function, // 自定义合计函数
    vueWindow: {
      // 是否开启虚拟滚动 开启的同时需指定height属性
      type: Boolean,
      default: false
    },
    reserveSelection: {
      // 列表数据渲染时是否保留之前的选中状态  前提需指定row-key属性
      type: Boolean,
      default: false
    },
    DetailTitle: {
      // 详情标题
      type: String,
      default: ""
    },
    ExcelTableName: {
      // 导出文件名
      type: String,
      default: ""
    },
    ShowDownload: {
      //是否显示下载按钮
      type: Boolean,
      default: false
    },
    rowKey: String // 列表唯一字段
  },
  data() {
    return {
      columns: [],
      translate: 0,
      scrollLeft: 0,
      sortIndex: null,
      type: null,
      TableBodyHeight: 0,
      DTableBodyHeight: "height: 100%;",
      DTableCheckAll: false,
      indeterminate: false,
      currentIndex: -1,
      RenderData: [],
      CheckKeys: [],
      CheckRowKey: {},
      isFixedIndex: null,
      isShowHeaders: [],
      isShowOperationPop: false,
      Headers: [],
      showExtrTh: false,
      resizeable: false,
      mousedown: false,
      targetTd: null,
      changeWidth: null,
      TableDetailWidth: 0,
      DTableContainerWidth: 0,
      DTableBoxID: null,
      noDataWidth: 0,
      OperationOpacity: 0,
      scrollWidth: 0,
      DTableBodyWidth: "100%",
      StartIndex: 0,
      ColIndex: -1,
      MaxEnd: 0,
      lefts: [],
      Right: [],
      center: [],
      SlotObj: [],
      HaveChildColumn: [],
      HeaderMaxDepth: 0,
      ExportErrorArr: Object.freeze([])
    };
  },
  computed: {
    json_fields() {
      let obj = {};
      this.$slots.default.forEach(v => {
        if (v.tag && v.componentOptions.propsData.prop) {
          if (v.data.attrs.exportName) {
            let arr1 = v.data.attrs.exportName.split("-");
            let arr2 = v.componentOptions.propsData.prop.split("-");
            arr1.forEach((v, i) => {
              obj[v] = arr2[i];
            });
          } else {
            obj[v.componentOptions.propsData.type || v.data.attrs.label] =
              v.componentOptions.propsData.prop;
          }
        }
      });
      return obj;
    },
    JudgeHaveHeaders() {
      let ShowHeaderList = [];
      for (let item of this.isShowHeaders) {
        if (item) {
          let splitItem = item.split(" |-| ");
          for (let it of splitItem) {
            if (!ShowHeaderList.includes(it)) {
              ShowHeaderList.push(it)
            }
          }
        }
        else {
          ShowHeaderList.push(item);
        }
      }
      return ShowHeaderList;
    },
  },
  watch: {
    data: {
      handler: function(e) {
        this.ExportErrorArr = Object.freeze(g.merge([], e));
        this.CheckKeys = [];
        if (this.rowKey && this.reserveSelection) {
          e.map((v, i) => {
            if (this.CheckRowKey[v[this.rowKey]]) {
              this.CheckKeys.push(i);
            }
          });
        }
        this.LoadTable(e);
      },
      deep: true
    },
    CheckKeys(e) {
      this.indeterminate = false;
      this.DTableCheckAll = false;
      if (this.CheckKeys.length) {
        if (this.CheckKeys.length !== this.data.length) {
          this.indeterminate = true;
          this.DTableCheckAll = false;
        } else {
          this.DTableCheckAll = true;
        }
      }
      this.$emit(
        "selection-change",
        this.rowKey && this.reserveSelection
          ? Object.values(this.CheckRowKey)
          : e.map(v => this.data[v])
      );
    }
  },
  activated() {
    this.onScrollBody();
  },
  created() {
    this.initSlot();
    this.TableBodyHeight = this.vueWindow ? this.data.length * this.height : 0;
    this.DTableBoxID = GetUuid();
    if (this.vueWindow && !this.height) {
      console.error(
        "When virtual scrolling is enabled, it must be passed to height!"
      );
    }
    this.calWidth();
  },
  mounted() {
    this.data.length && this.LoadTable(this.data);
    let height = this.$refs.DTableHead.getBoundingClientRect().height;
    this.DTableBodyHeight = `height: calc(100% - ${(height || 0) +
      (this.showSummary ? 40 : 0)}px)`;
  },
  render(h) {
    return (
      <div
        class="DTableBox"
        ref="DTableBox"
        id={this.DTableBoxID}
        onMousemove={this.TableMousemove}
        onMouseup={this.TableMouseup}
        onMouseleave={this.TableBoxMouseleave}
      >
        <div
          class="DTableContainer"
          style={
            this.DTableContainerWidth && `width:${this.DTableContainerWidth}px;`
          }
        >
          <div
            class="DTableHead"
            ref="DTableHead"
            style={`overflowY: ${this.showExtrTh ? "scroll" : "hidden"};`}
          >
            <table
              border="0"
              cellspacing="0"
              cellpadding="0"
              style="width:100%"
            >
              <colgroup>{this.initColGroup(h, "thead")}</colgroup>
              <thead>
                { this.initThead() }
              </thead>
            </table>
          </div>
          <div
            class="DTableBody"
            ref="DtableBody"
            style={this.DTableBodyHeight}
            on-scroll={e => this.onScrollBody(e)}
            onMousedown={this.TableMousedown}
          >
            {this.data.length ? (
              <div
                style={`width:100%;height:${
                  this.TableBodyHeight ? this.TableBodyHeight + "px" : "auto"
                };`}
              >
                <table
                  ref="DlScrollTable"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  style={`width:${this.DTableBodyWidth};top:${this.translate}px;position:absolute;`}
                >
                  <colgroup>{this.initColGroup(h, "tbody")}</colgroup>
                  <tbody>{this.getContentTd()}</tbody>
                </table>
              </div>
            ) : (
              <div style={`width:${this.noDataWidth}px`} class="DlTableNoData">
                {this.$slots.empty}
              </div>
            )}
          </div>
          {this.showSummary && (
            <div
              class="DTableFoot"
              ref="DTableFoot"
              style={`overflowY: ${this.showExtrTh ? "scroll" : "hidden"};`}
            >
              <table
                border="0"
                cellspacing="0"
                cellpadding="0"
                style="width:100%"
              >
                <colgroup>{this.initColGroup(h, "tfoot")}</colgroup>
                <tfoot>
                  <tr style="height:40px;">{this.initFoot()}</tr>
                </tfoot>
              </table>
            </div>
          )}
        </div>
        {this.DetailDrawer(h)}
        {this.isOperation && !this.TableDetailWidth && this.initOperation()}
      </div>
    );
  },
  methods: {
    IsHaveChildren(child) {
      if (!child) {
        return false;
      }
      return child.filter(v => v?.componentOptions?.tag === 'dl-table-column').length > 0
    },
    initSlotChildren(child, parentLabel, parentArr, lastIndex) {
      let arr = [];
      let childIndex = -1;
      for (let item of child) {
        let label = this.initEvalLabel(item) || "undefined";
        let it = {
          label: label,
          parent: `${parentLabel} |-| ${label}`,
          hidden: item.data.attrs.hidden,
          width: item.data.attrs.width,
          DetailFixedCol: item.data.attrs.DetailFixedCol,
          prop: item.componentOptions.propsData.prop,
          Summary: item.data.attrs.Summary,
          NoSummary: item.data.attrs.NoSummary
        }
        childIndex += 1;
        item.lastIndex = `${lastIndex},${childIndex}`;
        if (this.IsHaveChildren(item.componentOptions.children)) {
          item.haveChildColumn = true;
          !parentArr && this.HaveChildColumn.push(label);
          it.children = this.initSlotChildren(item.componentOptions.children, it.parent, parentArr, item.lastIndex)
        }
        else {
          this.ColIndex += 1;
          item.ColIndex = this.ColIndex;
          if (!item.data.attrs.hidden && !parentArr) {
            this.isShowHeaders.push(it.parent);
          }
          this.columns.push({
            property: item.componentOptions.propsData.prop,
            Summary: item.data.attrs.Summary,
            NoSummary: item.data.attrs.NoSummary
          });
        }
        arr.push(it);
      }
      return arr.length ? arr : null;
    },
    initSlot(arr) {
      let lefts = [];
      let Right = [];
      let center = [];
      this.Headers = [];
      if (!arr) {
        this.isShowHeaders = [];
        this.HaveChildColumn = [];
      }
      this.columns = [];
      let lastIndex = -1;
      this.ColIndex = -1;
      (arr || this.$slots.default).forEach(v => {
        if (v.tag) {
          let label = this.initEvalLabel(v) || "undefined";
          let item = {
            label,
            parent: label,
            hidden: v.data.attrs.hidden,
            width: v.data.attrs.width,
            DetailFixedCol: v.data.attrs.DetailFixedCol,
            prop: v.componentOptions.propsData.prop,
            Summary: v.data.attrs.Summary,
            NoSummary: v.data.attrs.NoSummary
          }
          v.lastIndex = lastIndex += 1;
          if (this.IsHaveChildren(v.componentOptions.children)) {
            v.haveChildColumn = true;
            !arr && this.HaveChildColumn.push(label);
            item.children = this.initSlotChildren(v.componentOptions.children, item.parent, arr, v.lastIndex);
          }
          else {
            this.ColIndex += 1;
            v.ColIndex = this.ColIndex;
            if (!v.data.attrs.hidden && !arr) {
              this.isShowHeaders.push(item.parent);
            }
            this.columns.push({
              property: v.componentOptions.propsData.prop,
              Summary: v.data.attrs.Summary,
              NoSummary: v.data.attrs.NoSummary
            });
          }
          this.Headers.push(item);
          v.Guid = GetUuid();
          switch (v.componentOptions.propsData.fixed) {
            case "left":
              lefts.push(v);
              break;
            case "right":
              Right.push(v);
              break;
            default:
              center.push(v);
              break;
          }
        }
      });
      if (!arr) {
        this.SlotObj = [...lefts, ...center, ...Right];
        this.HeaderMaxDepth = 0;
        for (let item of this.isShowHeaders) {
          if (item && item.split(" |-| ").length > this.HeaderMaxDepth) {
            this.HeaderMaxDepth = item.split(" |-| ").length;
          }
        }
      }
    },
    calWidth() {
      this.$nextTick(() => {
        setTimeout(() => {
          let head = this.$refs.DTableHead;
          if (head) {
            this.noDataWidth = head.querySelector("table").offsetWidth;
          }
        }, 500)
      });
    },
    initColGroup(h, origin, arr) {
      let Cols = [];
      (arr || this.SlotObj).forEach((item, idx) => {
        if (!this.IsHaveChildren(item.componentOptions.children) && this.JudgeHaveHeaders.includes(this.initEvalLabel(item))) {
          Cols.push(h("col", {
            key: `dl_${origin}_col_${idx}`,
            attrs: {
              width: this.summaryHeaderWidth(h, { column: item, $index: idx }) || item.data.attrs.width
            }
          }));
        }
        if (this.IsHaveChildren(item.componentOptions.children)) {
         Cols = [...Cols, ...this.initColGroup(h, origin, item.componentOptions.children)]
        }
      })
      return Cols;
    },
    
    initThead(idx, arr) {
      let theadsObj = {};
      let theadsArr = [];
      let AddLength = 0;
      if (idx) {
        AddLength = idx;
      }
      (arr || this.SlotObj).forEach((item, i) => {
        if (this.JudgeHaveHeaders.includes(this.initEvalLabel(item))) {//this.isShowHeaders.includes(item.componentOptions.propsData.type || item.data.attrs.label)) {
          if (!theadsObj[AddLength]) {
            theadsObj[AddLength] = [this.initTheadTd(item, i, AddLength)]
          }
          else {
            theadsObj[AddLength].push(this.initTheadTd(item, i, AddLength))
          }
        }
        if (this.IsHaveChildren(item.componentOptions.children)) {
          let obj = this.initThead(AddLength + 1, item.componentOptions.children);
          for (let key in obj) {
            if (theadsObj[key]) {
              theadsObj[key] = [...theadsObj[key], ...obj[key]]
            }
            else {
              theadsObj[key] = obj[key]
            }
          }
        }
      });
      if (!idx) {
        for (let key in theadsObj) {
          theadsArr.push(<tr>{ theadsObj[key] }</tr>)
        }
        return theadsArr;
      }
      else{
        return theadsObj;
      }
    },
    getColumnCols(children) {
      if (this.IsHaveChildren(children)) {
        return children.filter(v => this.JudgeHaveHeaders.includes(this.initEvalLabel(v))).length
      }
      else {
        return 1
      }
    },
    getColumnRows(StartRow) {
      return this.HeaderMaxDepth - StartRow
    },
    initTheadTd(item, i, AddLength) {
      switch (item.componentOptions.propsData.type) {
        case "selection":
          return (
            <th
              dataindex={ item.lastIndex }
              haveChild={ item.haveChildColumn ? 2 : 1 }
              onMousedown={e => this.ThMousedown(e, i, item.Guid)}
              onMousemove={e =>
                this.onThMove(e, item.componentOptions.propsData.fixed)
              }
              colspan={!this.IsHaveChildren(item.componentOptions.children) ? 1 : this.getColumnCols(item.componentOptions.children)}
              key={`dl_th_${i}`}
              rowspan={this.IsHaveChildren(item.componentOptions.children) ? 1 : this.getColumnRows(AddLength)}
              class={`${this.GetFixedObj(
                "class",
                item.componentOptions.propsData.fixed,
                i
              )} ${
                this.isFixedIndex == i ? "DTableFixedLeftShowdow" : ""
              } DlTableTh ${
                this.headerCellClassName
                  ? this.headerCellClassName({
                      column: item,
                      columnIndex: i
                    })
                  : ""
              }`}
              onMouseup={e => this.ThMouseup(e)}
              style={this.GetFixedObj(
                "style",
                item.componentOptions.propsData.fixed,
                i
              )}
              ref={item.Guid}
            >
              {this.isShowAllCheck && (
                <el-checkbox
                  v-model={this.DTableCheckAll}
                  disabled={!this.data.length}
                  indeterminate={this.indeterminate}
                  onchange={this.onChange_CheckAll}
                />
              )}
            </th>
          );
        case "index":
          return (
            <th
              haveChild={ item.haveChildColumn ? 2 : 1 }
              dataindex={ item.lastIndex }
              onMousedown={e => this.ThMousedown(e, i, item.Guid)}
              onMousemove={e =>
                this.onThMove(e, item.componentOptions.propsData.fixed)
              }
              colspan={!this.IsHaveChildren(item.componentOptions.children) ? 1 : this.getColumnCols(item.componentOptions.children)}
              key={`dl_th_${i}`}
              rowspan={this.IsHaveChildren(item.componentOptions.children) ? 1 : this.getColumnRows(AddLength)}
              class={`${this.GetFixedObj(
                "class",
                item.componentOptions.propsData.fixed,
                i
              )} DlTableTh ${
                this.headerCellClassName
                  ? this.headerCellClassName({
                      column: item,
                      columnIndex: i
                    })
                  : ""
              }`}
              onMouseup={e => this.ThMouseup(e)}
              style={this.GetFixedObj(
                "style",
                item.componentOptions.propsData.fixed,
                i
              )}
              ref={item.Guid}
            >
              <div class="cell">{item.data.attrs.label || "#"}</div>
            </th>
          );
        default:
          let Hhtml = item.componentOptions?.children?.find(
            v => v.data.slot === "header"
          );
          return (
            <th
              haveChild={ item.haveChildColumn ? 2 : 1 }
              dataindex={ item.lastIndex }
              onMousemove={e =>
                this.onThMove(e, item.componentOptions.propsData.fixed)
              }
              colspan={!this.IsHaveChildren(item.componentOptions.children) ? 1 : this.getColumnCols(item.componentOptions.children)}
              key={`dl_th_${i}`}
              rowspan={this.IsHaveChildren(item.componentOptions.children) ? 1 : this.getColumnRows(AddLength)}
              class={`${this.GetFixedObj(
                "class",
                item.componentOptions.propsData.fixed,
                i
              )} DlTableTh ${
                this.headerCellClassName
                  ? this.headerCellClassName({
                      column: item,
                      columnIndex: i
                    })
                  : ""
              }`}
              style={
                "text-align:" +
                (item.componentOptions.propsData.align || "left") +
                ";" +
                `border-bottom:${item.haveChildColumn?'1px solid #e2e5ec;':'none;'}`+
                this.GetFixedObj(
                  "style",
                  item.componentOptions.propsData.fixed,
                  i
                )
              }
              ref={item.Guid}
              onMousedown={e => this.ThMousedown(e, i, item.Guid)}
              onMouseup={e => this.ThMouseup(e)}
            >
              <div
                class="cell"
                onClick={() =>
                  this.onClick_DTableSort(
                    i,
                    item.componentOptions.propsData.sortable
                  )
                }
              >
                {Hhtml ? (
                      Hhtml.children
                    ) : item.data.attrs.enlabel ? (
                      <div class="tableheader">
                        <div>{item.data.attrs.enlabel}</div>
                        <div>{item.data.attrs.label}</div>
                      </div>
                    ) : (
                      item.data.attrs.label
                    )}
                {typeof item.componentOptions.propsData.sortable ===
                  "string" && (
                  <span class="caret-wrapper">
                    <i
                      class={
                        this.sortIndex == i && this.type == 2
                          ? "sort-caret ascending ascAct"
                          : "sort-caret ascending"
                      }
                      onClick={e =>
                        this.onClick_DTableSort(
                          i,
                          item.componentOptions.propsData.sortable,
                          2,
                          e
                        )
                      }
                    ></i>
                    <i
                      class={
                        this.sortIndex == i && this.type == 1
                          ? "sort-caret descending descAct"
                          : "sort-caret descending"
                      }
                      onClick={e =>
                        this.onClick_DTableSort(
                          i,
                          item.componentOptions.propsData.sortable,
                          1,
                          e
                        )
                      }
                    ></i>
                  </span>
                )}
              </div>
            </th>
          );
      }
    },
    getContentTd() {
      return this.RenderData.map((v, i) => {
        let classStr = "";
        if (
          this.highlightCurrentRow &&
          (v.RowIndex || i) == this.currentIndex
        ) {
          classStr += " highlight-row";
        }
        if (this.stripe && (v.RowIndex || i) % 2 === 1) {
          classStr += " stripeTr";
        }
        return (
          <tr
            key={`dl_tr_${v.RowIndex || i}`}
            style={`${this.vueWindow ? "height:" + this.height + "px;" : ""}`}
            class={classStr}
            onClick={() => {
              this.currentIndex = v.RowIndex || i;
              this.$emit('row-click',v)
            }}
          >
            {this.initBodyTd("", v, i)}
          </tr>
        );
      });
    },
    initBodyTd(arr, v, i) {
      let rendArr = [];
      (arr || this.SlotObj).forEach((item, idx) => {
        if (!this.IsHaveChildren(item.componentOptions.children)) {
          if (this.JudgeHaveHeaders.includes(this.initEvalLabel(item)))
          {
            rendArr.push(<item.componentOptions.tag
              key={`dl_td_${v.RowIndex || i}_${idx}`}
              Row={v}
              rowIndex={v.RowIndex || i}
              prop={item.componentOptions.propsData.prop}
              Children={
                item.data.scopedSlots?.default({
                  row: v,
                  $index: v.RowIndex || i
                }) ||
                item.componentOptions.children?.filter(
                  v => v.data.slot !== "header"
                )
              }
              type={item.componentOptions.propsData.type}
              hiddenStr={item.componentOptions.propsData.hiddenStr}
              fixed={
                item.componentOptions.propsData.fixed === ""
                  ? "left"
                  : item.componentOptions.propsData.fixed
              }
              cellIndex={idx}
              align={item.componentOptions.propsData.align}
              disabled={
                item.data.attrs.selectable &&
                item.data.attrs.selectable(v, v.RowIndex || i)
              }
              reserveSelection={this.reserveSelection}
              showOverflowTooltip={this.vueWindow ? true : 
                item.componentOptions.propsData.showOverflowTooltip === ""
                  ? true
                  : item.componentOptions.propsData.showOverflowTooltip
              }
              rowKey={this.rowKey}
            ></item.componentOptions.tag>)
          }
        }
        else {
          rendArr = [...rendArr, ...this.initBodyTd(item.componentOptions.children, v, i)]
        }
      })
      return rendArr;
    },
    initFoot(arr) {
      let renderFoots = [];
      (arr || this.SlotObj).forEach((item, idx) => {
        if (!this.IsHaveChildren(item.componentOptions.children)) {
          if (this.JudgeHaveHeaders.includes(this.initEvalLabel(item))) {
            renderFoots.push(
              <td
                key={`foot-${item.ColIndex}`}
                colspan="1"
                rowspan="1"
                align={idx==0?'center':item.componentOptions.propsData.align}
                class={this.GetFixedObj(
                  "class",
                  item.componentOptions.propsData.fixed,
                  item.ColIndex
                )}
                style={this.GetFixedObj(
                  "style",
                  item.componentOptions.propsData.fixed,
                  item.ColIndex
                )}
              >
                {this.SummaryList ? this.SummaryList[item.ColIndex] : ""}
              </td>
            );
          }
        }
        else {
          renderFoots = [...renderFoots, ...this.initFoot(item.componentOptions.children)]
        }
      });
      return renderFoots;
    },
    initOperation() {
      return (
        <div onMousemove={this.Operationmove}>
          <div
            class="DlTableOperation"
            style={`opacity:${this.OperationOpacity}`}
          >
            <i
              class="el-icon-s-tools"
              onClick={() => (this.isShowOperationPop = true)}
            ></i>
          </div>
          <operation
            v-model={this.isShowOperationPop}
            headers={this.Headers}
            isShowHeaders={this.isShowHeaders}
            haveChildColumn={this.HaveChildColumn}
            onChange={e => {
              this.isShowHeaders = e.data; 
              this.HeaderMaxDepth = e.max; 
              this.$nextTick(() => {
                this.DTableBodyHeight = `height: calc(100% - ${(this.$refs.DTableHead.clientHeight || 0) + (this.showSummary ? 40 : 0)}px)`;
                this.calWidth();
                this.$forceUpdate();
              });
            }}
          />
        </div>
      );
    },
    initEvalLabel(item) {
      return item.componentOptions.propsData.type || item.data.attrs.label || item.componentOptions.propsData.prop || 'undefined';
    },
    DetailDrawer() {
      return (
        <div
          style={`width:${this.TableDetailWidth}`}
          class={`DetailDrawer ${
            this.TableDetailWidth ? "DetailDrawerShadow" : ""
          }`}
        >
          <div class="TableDetailContent">
            {this.TableDetailWidth ? this.$slots.Detail : ""}
          </div>
        </div>
      );
    },
    LoadTable(e) {
      this.currentIndex = -1;
      this.$set(
        this,
        "TableBodyHeight",
        this.vueWindow ? this.data.length * this.height : 0
      );
      if (this.vueWindow) {
        this.onScrollBody();
      } else {
        this.RenderData = e;
      }
      this.SummaryTable(e);
      this.$nextTick(() => {
        this.DTableBodyHeight = `height: calc(100% - ${(this.$refs.DTableHead
          .clientHeight || 0) + (this.showSummary ? 40 : 0)}px)`;
        const {
          offsetWidth,
          clientWidth,
          scrollHeight,
          clientHeight
        } = this.$refs.DtableBody;
        this.showExtrTh = scrollHeight > clientHeight;
        if (this.showExtrTh) {
          if (!this.scrollWidth && this.DTableContainerWidth) {
            this.DTableContainerWidth += offsetWidth - clientWidth;
            this.TableDetailWidth = `calc(100% - ${this.DTableContainerWidth}px)`;
          }
          this.scrollWidth = offsetWidth - clientWidth;
        }
      });
    },
    SummaryTable(data) {
      if (this.showSummary) {
        if (this.summaryMethod) {
          this.SummaryList = this.summaryMethod({
            columns: this.columns,
            data
          });
        } else {
          this.SummaryList = [];
          this.columns.forEach((column, index) => {
            if (index === 0) {
              this.SummaryList[index] = "合计";
              return;
            }
            if(this.IsChooseSummary){
              if (!column.Summary) {//NoSummary修改
                this.SummaryList[index] = "";
                return;
              }
            }else{
              if (column.NoSummary) {
                this.SummaryList[index] = "";
                return;
              }
            }  
            const values = data.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value) || value == "")) {
              this.SummaryList[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              this.SummaryList[index] = this.$options.filters.handleNum(
                this.SummaryList[index],
                2,
                false
              );
            } else {
              this.SummaryList[index] = "";
            }
          });
        }
      }
    },
    onScrollBody(e) {
      if (!e || this.scrollLeft === e.target.scrollLeft) {
        if (this.vueWindow) {
          const { scrollTop, clientHeight } = this.$refs.DtableBody;
          let StartIndex = Math.round(scrollTop / this.height);
          let SliceStart = StartIndex - 1 < 0 ? 0 : StartIndex - 1;
          this.RenderData = this.data.slice(
            SliceStart,
            StartIndex + Math.ceil(clientHeight / this.height) + 1
          );
          for (let item of this.RenderData) {
            item.RowIndex = SliceStart;
            SliceStart += 1;
          }
          this.translate = this.RenderData.length>0?this.RenderData[0].RowIndex * this.height:0;
        }
      } else {
        this.scrollLeft = e.target.scrollLeft;
        this.$refs.DTableHead.scrollLeft = this.scrollLeft;
        this.showSummary &&
          (this.$refs.DTableFoot.scrollLeft = this.scrollLeft);
      }
    },
    GetFixedObj(type, obj, index) {
      if (this.DTableContainerWidth) {
        return "";
      }
      switch (obj) {
        case "":
          if (type === "class") {
            let LeftFixeds = this.SlotObj.filter(v => {
              if (this.JudgeHaveHeaders.includes(this.initEvalLabel(v)) && v.componentOptions.propsData.fixed === "") {
                return v;
              }
            });
            return `DTableFixedLeft ${
              LeftFixeds.length - 1 == index ? "DTableFixedLeftShowdow" : ""
            }`;
          }
          return index > 0 ? `left:${this.GetPreWidth(index)}px` : "";
        case "left":
          if (type === "class") {
            let LeftFixeds = this.SlotObj.filter(v => {
              if (this.JudgeHaveHeaders.includes(this.initEvalLabel(v)) && v.componentOptions.propsData.fixed === "left") {
                return v;
              }
            });
            return `DTableFixedLeft ${
              LeftFixeds.length - 1 == index ? "DTableFixedLeftShowdow" : ""
            }`;
          }
          return index > 0 ? `left:${this.GetPreWidth(index)}px` : "";
        case "right":
          if (type === "class") {
            let RightFixeds = this.SlotObj.filter((v, i) => {
              if (this.JudgeHaveHeaders.includes(this.initEvalLabel(v)) && v.componentOptions.propsData.fixed === 'right') {
                v['FixedsIndex'] = i;
                return v;
              }
            });
            return `DTableFixedRight ${
              RightFixeds[0].FixedsIndex == index
                ? "DTableFixedRightShowdow"
                : ""
            }`;
          }
          return index == this.SlotObj.length - 1
            ? "right:0px;"
            : `right:${this.GetNextWidth(index)}px;z-index:3`;
        default:
          return "";
      }
    },
    GetPreWidth(idx) {
      let total = 0;
      this.SlotObj.slice(0, idx).map((v, _i) => {
        if (this.JudgeHaveHeaders.includes(this.initEvalLabel(v))) {
          total += Number(v.data.attrs.width);
        }
      });
      return total;
    },
    GetNextWidth(idx) {
      let total = 0;
      this.SlotObj.slice(idx + 1).map(v => {
        if (this.JudgeHaveHeaders.includes(this.initEvalLabel(v))) {
          total += Number(v.data.attrs.width);
        }
      });
      return total;
    },
    onChange_CheckAll(e) {
      this.CheckKeys = [];
      if (e) {
        const attrs = this.SlotObj.find(v => v.data.attrs.selectable)?.data
          ?.attrs;
        for (let i = 0; i < this.data.length; i++) {
          if (!attrs || attrs.selectable(this.data[i], i)) {
            this.CheckKeys.push(i);
            this.CheckRowKey[this.data[i][this.rowKey]] = this.data[i];
          }
        }
        if (!this.CheckKeys.length) {
          this.$message.warning("暂无可选行。");
        }
      } else {
        for (let i = 0; i < this.data.length; i++) {
          delete this.CheckRowKey[this.data[i][this.rowKey]];
        }
      }
      this.$emit(
        "select-all",
        this.rowKey && this.reserveSelection
          ? Object.values(this.CheckRowKey)
          : this.CheckKeys.map(v => this.data[v])
      );
      this.indeterminate = false;
    },
    clearSelection() {
      this.CheckKeys = [];
      this.CheckRowKey = {};
      this.indeterminate = false;
    },
    toggleRowSelection(item, isShowSelect = true) {
      if (this.rowKey && this.reserveSelection) {
        // console.error(
        //   "存在reserve-selection渲染回显属性，它们目的结果相同暂不支持同时调用，如有需要请去除reserve-selection属性。"
        // );
        // return;
        for (let i = 0; i < this.data.length; i++) {
          if (item == this.data[i]) {
            let RowIdx = this.CheckRowKey[item[this.rowKey]];
            if (isShowSelect) {
              if (!RowIdx) {
                this.CheckRowKey[item[this.rowKey]] = item;
              }
            } else {
              if (RowIdx) {
                this.$nextTick(() => {
                  delete this.CheckRowKey[item[this.rowKey]];
                });
              }
            }
          }
        }
      }
      for (let i = 0; i < this.data.length; i++) {
        if (item == this.data[i]) {
          let RowIdx = this.CheckKeys.indexOf(i);
          if (isShowSelect) {
            if (RowIdx < 0) {
              this.CheckKeys.push(i);
            }
          } else {
            if (RowIdx > -1) {
              this.$nextTick(() => {
                this.CheckKeys.splice(RowIdx, 1);
              });
            }
          }
        }
      }
    },
    onClick_DTableSort(i, isSort, stopIdx, e) {
      ThisDownID = null;
      DownDom = null;
      if (typeof isSort !== "string") return;
      if (stopIdx) {
        e.stopPropagation();
        if (stopIdx == this.type) {
          this.sortIndex = null;
          this.type = null;
        } else {
          this.sortIndex = i;
          this.type = stopIdx;
        }
      } else {
        if (this.sortIndex == i) {
          if (this.type == 2) {
            this.sortIndex = null;
            this.type = null;
          } else {
            this.type = this.type == 1 ? 2 : 1;
          }
        } else {
          this.sortIndex = i;
          this.type = 1;
        }
      }
      this.$emit("sort-change", {
        order:
          this.sortIndex !== null
            ? this.type == 1
              ? "descending"
              : "ascending"
            : null,
        prop: this.SlotObj[i].componentOptions.propsData.prop
      });
    },
    ThMouseup() {
      if (ThisDownID) {
        let ThisDownDom = document.getElementById(ThisDownID);
        ThisDownDom?.removeEventListener("mouseup", this.ThisDownIDMouseup);
        ThisDownDom?.remove();
        ThisDownID = null;
      }
      DownDom = null;
      clearInterval(DownTimer);
      DownTime = null;
      DownTimer = null;
      this.resizeable = false;
    },
    ThMousedown(e, i, Guid) {
      e.stopPropagation();
      if (this.resizeable) {
        this.mousedown = true;
        return;
      }
      DownTime = 1;
      DownTimer = setInterval(() => {
        DownTime += 0.2;
        if (DownTime >= 1.2) {
          clearInterval(DownTimer);
          DownTimer = null;
          DownTime = null;
          if (
            !this.SlotObj[i].componentOptions.propsData.fixed &&
            this.SlotObj[i].componentOptions.propsData.type !== "selection"
          ) {
            // 固定列以及复选列不允许进行拖拽
            /* 生成被点击节点的副本Dom */
            DownDom = e.target;
            while (DownDom.tagName !== "TH") {
              DownDom = DownDom.parentNode;
            }
            l = DownDom.offsetLeft - this.$refs.DtableBody.scrollLeft;
            DownDom = DownDom.cloneNode(true);
            x = e.clientX;
            y = e.clientY;
            const { clientWidth, clientHeight } = this.$refs[Guid];
            DownDom.style.width = `${clientWidth}px`;
            DownDom.style.height = `${clientHeight}px`;
            DownDom.style.lineHeight = `${clientHeight - 8}px`;
            DownDom.style.opacity = 0.5;
            DownDom.style.position = "absolute";
            DownDom.style.left = `${l}px`;
            DownDom.style.top = 0;
            ThisDownID = Guid;
            DownDom.setAttribute("id", ThisDownID);
            DownDom.addEventListener("mouseup", this.ThisDownIDMouseup);
            document.getElementById(this.DTableBoxID).appendChild(DownDom);
          }
        }
      }, 200);
    },

    TableMousedown(e) {
      isMouseDown = e.target.tagName !== "INPUT";
      startX = e.clientX;
    },

    TableMousemove(e) {
      e.stopPropagation();
      this.OperationOpacity = 1;
      if (ThisDownID) {
        /* 往table盒子插入副本DOM 并 拖拽计算 */
        const { offsetWidth, offsetHeight } = document.getElementById(
          this.DTableBoxID
        );
        let nl = e.clientX - (x - l);
        let nt = e.clientY - y;
        let ThisDownDom = document.getElementById(ThisDownID);
        nl > 0 &&
          nl + DownDom.offsetWidth < offsetWidth &&
          (ThisDownDom.style.left = nl + "px"); // 实施更新虚拟节点的位置
        nt > 0 &&
          nt + DownDom.offsetHeight < offsetHeight &&
          (ThisDownDom.style.top = nt + "px"); // 实施更新虚拟节点的位置
        let DragStartX = ThisDownDom.offsetLeft; // 获取移动节点的最左端离盒子的X轴距离
        let DragEndX = DragStartX + ThisDownDom.clientWidth; // 获取移动节点的最右端离盒子的X轴距离
        CoverDom = {}; // 存储经过的节点
        const {
          scrollLeft,
          clientHeight: BodyClientHeight
        } = this.$refs.DtableBody;
        /* 计算出经过的节点 并 计算占比率 Start */
        let Proportion = null;
        for (let i = 0; i < this.SlotObj.length; i++) {
          // if (
          //   this.isShowHeaders.includes(
          //     this.SlotObj[i].componentOptions.propsData.type ||
          //       this.SlotObj[i].data.attrs.label
          //   )
          // ) {
          if (this.JudgeHaveHeaders.includes(this.initEvalLabel(this.SlotObj[i]))) {
            const { offsetLeft, clientWidth, clientHeight } = this.$refs[
              this.SlotObj[i].Guid
            ];
            if (
              DragStartX > offsetLeft + clientWidth - scrollLeft ||
              DragEndX < offsetLeft - scrollLeft
            ) {
              continue;
            }
            Proportion = null;
            if (
              DragStartX > offsetLeft - scrollLeft &&
              DragEndX < offsetLeft + clientWidth - scrollLeft
            ) {
              Proportion = (DragEndX - DragStartX) / clientWidth;
            }
            if (
              DragStartX <= offsetLeft - scrollLeft &&
              DragEndX >= offsetLeft + clientWidth - scrollLeft
            ) {
              Proportion = 1;
            }
            if (
              DragEndX > offsetLeft + clientWidth - scrollLeft &&
              DragStartX > offsetLeft - scrollLeft
            ) {
              Proportion =
                (offsetLeft + clientWidth - DragStartX - scrollLeft) /
                clientWidth;
            }
            if (
              DragStartX < offsetLeft - scrollLeft &&
              DragEndX > offsetLeft - scrollLeft &&
              DragEndX < offsetLeft + clientWidth - scrollLeft
            ) {
              Proportion = (DragEndX - offsetLeft + scrollLeft) / clientWidth;
            }
            Proportion &&
              (CoverDom[Proportion] = {
                offsetLeft,
                clientHeight,
                clientWidth,
                Guid: this.SlotObj[i].Guid,
                nextIsDisabled: this.SlotObj[i + 1]
                  ? typeof this.SlotObj[i + 1].componentOptions.propsData
                      .fixed === "string"
                  : true,
                currentIsDisabled:
                  typeof this.SlotObj[i].componentOptions.propsData.fixed ===
                  "string"
              });
          }
        }
        /* 计算出经过的节点 并 计算占比率 End */
        if (!Object.values(CoverDom).length) return;
        maxDom = Math.max(...Object.keys(CoverDom)); // 比较经过的结果 获取占比最多的
        const { clientHeight, offsetLeft, clientWidth } = CoverDom[maxDom];
        /* 操作竖线动作 Start */
        if (document.getElementById("DlTableCline")) {
          document.getElementById(
            "DlTableCline"
          ).style.transform = `translateX(${offsetLeft +
            clientWidth -
            scrollLeft}px)`;
        } else {
          let Cline = document.createElement("div");
          Cline.style.width = "1px";
          Cline.style.height = `${clientHeight + BodyClientHeight}px`;
          Cline.style.transform = `translateX(${offsetLeft +
            clientWidth -
            scrollLeft}px)`;
          Cline.style.background = "#409EFF";
          Cline.style.position = "absolute";
          Cline.style.top = 0;
          Cline.style.transition = "transform .3s ease";
          Cline.setAttribute("id", "DlTableCline");
          document.getElementById(this.DTableBoxID).appendChild(Cline);
        }
        /* 操作竖线动作 End */
      }
      if (isMouseDown) {
        try {
          let TargetDom = e.target;
          while (TargetDom.className.indexOf("DTableBody") < 0) {
            TargetDom = TargetDom.parentNode;
          }
          let x1 = e.clientX;
          let { scrollWidth, offsetWidth, scrollLeft } = TargetDom;
          if (x1 > startX && scrollLeft > 0) {
            TargetDom.scrollLeft -= x1 - startX;
          }
          if (x1 < startX && scrollLeft < scrollWidth - offsetWidth) {
            TargetDom.scrollLeft += startX - x1;
          }
          startX = x1;
        } catch {}
      }
    },
    TableMouseup(e) {
      isMouseDown = false;
      startX = null;
      // 设置调整后的宽度
      if (this.mousedown) {
        document.getElementById("DlTableCline")?.remove();
        if (this.changeWidth) {
          let dataindex = this.targetTd.getAttribute("dataindex");
          let index = "";
          for (let item of dataindex.split(",")){
            if (!index) {
              index = `[${item}]`
            }
            else {
              index = `${index}.componentOptions.children[${item}]`
            }
          }
          eval(`this.SlotObj${index}.data.attrs.width=this.changeWidth`);
          this.$forceUpdate();
          this.$nextTick(() => {
            this.DTableBodyHeight = `height: calc(100% - ${(this.$refs.DTableHead.clientHeight || 0) + (this.showSummary ? 40 : 0)}px)`;
            this.calWidth();
            this.$forceUpdate();
          });
        }
        this.targetTd = null;
        this.changeWidth = null;
        this.resizeable = false;
        this.mousedown = false;
        document.body.style.cursor = "default";
      }
      this.Operationmove(e);
    },
    TableBoxMouseleave(e) {
      e.stopPropagation();
      if (ThisDownID) {
        this.ThisDownIDMouseup(e);
      }
      this.OperationOpacity = 0;
    },
    ThisDownIDMouseup(e) { 
      e.stopPropagation();
      /* 根据拖拽结果进行列表排序 Start */
      if (
        Object.values(CoverDom).length &&
        ThisDownID !== CoverDom[maxDom].Guid
      ) {
        if (
          !CoverDom[maxDom].currentIsDisabled ||
          (CoverDom[maxDom].currentIsDisabled &&
            !CoverDom[maxDom].nextIsDisabled)
        ) {
          let SortText = this.SlotObj[this.sortIndex]?.data?.attrs?.label;
          let sliceDomIdx = this.SlotObj.findIndex(v => v.Guid === ThisDownID);
          let sliceDom = this.SlotObj.splice(sliceDomIdx, 1);
          let insertIdx = this.SlotObj.findIndex(
            v => v.Guid === CoverDom[maxDom].Guid
          );
          this.SlotObj.splice(insertIdx + 1, 0, ...sliceDom);
          SortText &&
            (this.sortIndex = this.SlotObj.findIndex(
              v => v.data.attrs.label === SortText
            ));
          this.columns = [];
          this.Headers = [];
          this.initSlot(this.SlotObj); // 同步配置列的顺序
          this.SummaryTable(this.data);
          this.$forceUpdate();
        } else {
          this.$message.warning("固定列顺序不允许被替代。");
        }
      }
      /* 根据拖拽结果进行列表排序 End */
      /* 销毁副本DOM并其它 */
      let ThisDownDom = document.getElementById(ThisDownID);
      ThisDownDom?.removeEventListener("mouseup", this.ThisDownIDMouseup);
      ThisDownDom?.remove();
      document.getElementById("DlTableCline")?.remove();
      CoverDom = {};
      ThisDownID = null;
      DownDom = null;
    },
    DragInitHeaders(arr) {
      for (let item of arr) {
        if (!item.children) {
          if (!item.hidden) {
            this.isShowHeaders.push(item.parent)
          }
          this.columns.push({
            property: item.prop,
            Summary: item.Summary,
            NoSummary: item.NoSummary
          })
        }
        if (item?.children?.length) {
          this.DragInitHeaders(item.children)
        }
      }
    },
    Operationmove(e) {
      document.getElementById(ThisDownID) && this.ThisDownIDMouseup(e);
    },
    onThMove(e, fixed) {
      let evt = e || window.event;
      let ele = e.target || e.srcElement;
      if (this.mousedown) {
        // 开始拖动并获取拖拽宽度
        ele.style.cursor = "col-resize";
        /* 操作竖线动作 Start */
        const { scrollLeft, clientHeight } = this.$refs.DtableBody;
        const { left } = this.$refs.DTableBox.getBoundingClientRect();
        let changeNum =
          evt.pageX - left - this.targetTd.offsetLeft + scrollLeft;
        let line = null;
        if (changeNum <= 40) {
          line = this.targetTd.offsetLeft - scrollLeft + 40;
          this.changeWidth = 40;
        } else {
          line = evt.pageX - left;
          this.changeWidth = changeNum;
        }
        if (document.getElementById("DlTableCline")) {
          document.getElementById(
            "DlTableCline"
          ).style.transform = `translateX(${line}px)`;
        } else {
          let Cline = document.createElement("div");
          Cline.style.width = "1px";
          Cline.style.height = `${ele.clientHeight + clientHeight}px`;
          Cline.style.transform = `translateX(${line}px)`;
          Cline.style.background = "#ccc";
          Cline.style.position = "absolute";
          Cline.style.top = "0px";
          Cline.setAttribute("id", "DlTableCline");
          document.getElementById(this.DTableBoxID).appendChild(Cline);
        }
        /* 操作竖线动作 End */
      } else {
        // 符合位置下显示对于的光标
        if (
          ele.offsetWidth - evt.offsetX <= 4 &&
          !fixed &&
          ele.tagName === "TH" && ele.getAttribute("haveChild") == "1"
        ) {
          this.targetTd = ele;
          this.resizeable = true;
          this.targetTd.style.cursor = "col-resize"; // 修改光标样式
        } else {
          this.resizeable = false;
          ele.style.cursor = ele.tagName === "SPAN" ? "pointer" : "default";
        }
      }
    },
    OpenDetailDrawer() {
      return new Promise(resolve => {
        if (!this.DTableContainerWidth) {
          this.isShowHeaders = [];
          this.DTableContainerWidth = this.scrollWidth;
          this.Headers.forEach(v => {
            if (v.DetailFixedCol) {
              this.DTableContainerWidth += Number(
                String(v.width).replace(/px/, "")
              );
              this.isShowHeaders.push(v.parent);
            }
          });
          let DetailContent = document.getElementsByClassName(
            "TableDetailContent"
          )[0];
          DetailContent.style.whiteSpace = "nowrap";
          this.TableDetailWidth = `calc(100% - ${this.DTableContainerWidth}px)`;
          this.noDataWidth = this.DTableContainerWidth;
          this.$nextTick(() => {
            const { scrollHeight, clientHeight } = this.$refs.DtableBody;
            this.showExtrTh = scrollHeight > clientHeight;
            setTimeout(() => {
              DetailContent.style.whiteSpace = "unset";
              DetailContent = null;
              resolve();
            }, 500);
          });
        } else {
          resolve();
        }
      });
    },
    CloseDetailDrawer() { 
      this.isShowHeaders = [];
      this.$nextTick(() => {
        this.ResetHeaders(this.Headers);
        this.TableDetailWidth = 0;
        this.DTableContainerWidth = 0;
        this.calWidth();
        this.$nextTick(() => {
          const { scrollHeight, clientHeight } = this.$refs.DtableBody;
          this.showExtrTh = scrollHeight > clientHeight;
          this.$forceUpdate();
        });
      })
      
    },
    ResetHeaders(arr) {
      for (let item of arr) {
        if (!item.hidden && !item.children) {
          this.isShowHeaders.push(item.parent)
        }
        if (item?.children?.length) {
          this.ResetHeaders(item.children)
        }
      }
    },
    onLoadTable() {
      this.initSlot();
      this.calWidth();
    }
  }
};
